@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-neutral-100:#AAB8C2;
    --color-neutral-200:#A3B1BA;
    --color-red-100: #B4F6A5;
    --color-red-200: #F48EA7;
    --color-purple-100: #CC8EF5;
    --color-purple-200: #D46ABF;
    --color-purple-300: #DD4688;
    --color-blue-100: #9FC7FA;
    --color-green-100: #9CD8C3;
    --motion-speed-exit: 0.15s;
    --motion-speed-hover: 0.2s;
    --primary-color: #B4F6A5;
    --onBackgroundII: rgba(255, 255, 255, 0.65);
    --outlineII: rgba(255, 255, 255, 0.12);
    --surface: #161719;
    color-scheme: only dark;
  }
  

* {
    /* scrollbar-width: none; */
    -ms-overflow-style: none;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 5px;
    animation: fadeIn 2s ease-in-out;
}


body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background: transparent;
}

body.show-scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 5px;
    animation: fadeIn 2s ease-in-out;
}

body.show-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #548C47;
    cursor: pointer;
}

.removeScrollBar::-webkit-scrollbar {
    display: none;
}

@keyframes fadeIn {
    10% {
        opacity: 0;
    }
    50%{ 
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



  
.bg-transparent__suggestions {
    max-height: 240px !important;
    overflow-y: auto;
}

.clip-custom-even {
    clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
}

.clip-custom-odd {
    clip-path: polygon(0 0, 100% 1%, 92% 100%, 0% 100%);
}



.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: var(--onBackgroundII);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
}

.app-intro-guide {
    background: transparent !important;
}

.introjs-arrow.bottom,
.introjs-arrow.bottom-middle,
.introjs-arrow.bottom-right {
    border-top-color: var(--primary-color) !important;
    bottom: -9px !important;
}

.introjs-arrow.top,
.introjs-arrow.top-middle,
.introjs-arrow.top-right {
    border-bottom-color: var(--primary-color) !important;
    top: -9px !important;
}

.introjs-arrow.right,
.introjs-arrow.right-bottom {
    border-left-color: var(--primary-color) !important;
    right: -9px !important;
}

.app-intro-guide .introjs-tooltiptext {
    color: black;
    padding: 12px 16px;
    background: var(--primary-color);
    font: bold 12px 'Inter' !important;
    border-radius: 10px;
}

.app-intro-guide .introjs-tooltip-header {
    display: none !important;
}

textarea {
    outline: none;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: #B4F6A5;
    text-shadow:
        1px 1px 1px rgba(0, 0, 0, 1),
        1px -1px 1px rgba(0, 0, 0, 1),
        -1px 1px 1px rgba(0, 0, 0, 1),
        -1px -1px 1px rgba(0, 0, 0, 1);
    pointer-events: none;
    background-color: #000;
    -webkit-text-fill-color: #B4F6A5;
    -webkit-font-smoothing: antialiased;
}

.mentions__mention-light {
    position: relative;
    z-index: 1;
    color: #548C47;
    text-shadow:
        1px 1px 1px rgba(255,255,255, 1),
        1px -1px 1px rgba(255,255,255, 1),
        -1px 1px 1px rgba(255,255,255, 1),
        -1px -1px 1px rgba(255,255,255, 1);
    pointer-events: none;
    background-color: #fff;
    -webkit-text-fill-color: #548C47;
    -webkit-font-smoothing: antialiased;
}

@media screen and (min-width: 630px) {
    #main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 750px;
        width: 420px;
        border: 2px solid var(--outlineII);
        overflow: hidden;
    }
}

.loading.loading-spinner.loading-md,
.loading.loading-ring.loading-md {
    background-color: var(--primary-color) !important
}

.custom-hover:hover{
    transition : all 450ms;
    box-shadow : 0 0 20px -2px #B4F6A580
}






.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {color: #f1c40f;}

.fa-sun {color: #f39c12;}

.checkbox-label .ball {
  background-color: #efefef;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox + .checkbox-label{
  background-color: #f6d54e;
}
.checkbox:checked + .checkbox-label{
  background-color: rgb(159, 159, 159);
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

.react-emoji-picker .epr-category-nav {
    padding: 0 10px !important;
}

.react-emoji-picker .epr-body::-webkit-scrollbar-thumb {
    background: #B4F6A5;
    border-radius: 5px;
}

.react-emoji-picker .epr-body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
}




